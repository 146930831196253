import React, { useState } from 'react'
import HeadingStyle from '../../../../../../../components/heading-style/heading-style';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ShippingAddressCard from '../../../../../../../components/address-card/shipping-add-card';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';
import dayjs from 'dayjs';
import { AdapterDayjs,  } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import FileUploadPopup from '../../../../../../../components/popup/file-upload-popup';
import CommonPopUp from '../../../../../../../components/popup/common-popup';
import { createPortal } from 'react-dom';
import { MenuPropsAutoComplete, MenuPropsAutoCompleteNoCheckBox, MenuPropsSelect } from '../../../../../../../core/utility/style';
import MaterialSymbol from '../../../../../../../components/icons/material-symbol';

type Props = {
    proposalData?: any;
    setShowInput?: any;
    showInput?: any;
    setAddressFormData?: any;
    addressFormData?: any;
    location?: any;
    setLocation?: any;
    loading?: any;
    saveAddress?: any;
    addressList?: any;
    sendAddress?: any;
    updateDefaultAddress?: any;
    sendReciviedProduct?: any;
    setProductDelvieryStatus?: any;
    setTermsAccept?: any;
    setRecivedProductDate?: any;
    setProductDeliveryRemark?: any;
    productDeliveryImageUrl?: (data: string) => void;
    productDeliveryImagePath?: (data: string) => void;
    productDeliveryImage?:any;
    setShowUploadPopup?: any;
    showUploadPopup?: any;
    proposalAddressId?: any;
}

const BarterProductInfo = ({ proposalData, setShowInput, showInput, setAddressFormData, addressFormData, location, setLocation, saveAddress, loading, addressList, sendAddress, updateDefaultAddress, sendReciviedProduct, setProductDelvieryStatus, setTermsAccept, setRecivedProductDate, setProductDeliveryRemark, productDeliveryImageUrl, productDeliveryImagePath, productDeliveryImage, setShowUploadPopup, showUploadPopup, proposalAddressId }: Props) => {
    const [handleCreative, setHandleCreative] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    function getAutoCompleteArrey(value: any) {
        let splitvalue = value.split(",");
        const temp = {
            city: splitvalue[0],
            state: splitvalue[1],
            country: splitvalue[2],
        };
        setAddressFormData({ ...addressFormData, ...temp });
    }
    const dateFormat = "YYYY-MM-DD";
    const handleCloseCreativeImageModal = () => {
        setHandleCreative(false)
        const bodyClass = document.getElementsByTagName('html')[0];
        if (bodyClass) {
            bodyClass.classList.remove("bodyInnerModalActive");
        }
    }
    
    const handleCreativeZoom = () => {
        setHandleCreative(true)
        const bodyClass = document.getElementsByTagName('html')[0];
        if (bodyClass) {
            bodyClass.classList.add("bodyInnerModalActive");
        }
    }
    const [isVisible, setIsVisible] = useState(true);

    const handleClosePreviewImage = () => {
        setIsVisible(false);
    };
    return (
        <div>
            <div className={`updateBarterProduct-container mt-3`}>
                <div className="product-detail-container">
                    <div className="product-detail-title-sec flex-row">
                        <div className="col">
                            <h5>
                                <strong>Step 1 (Influencer) :</strong> Address Details{" "}
                            </h5>
                            <h6>The product delivery address.</h6>
                        </div>
                        <div className="col text-end">
                            {proposalAddressId === 0 && (
                                <button type="button"
                                    className="btn btn-primary"
                                    onClick={() => setShowInput(!showInput)}
                                >
                                    Add address
                                </button>
                            )}
                        </div>
                    </div>

                    <div>
                        {showInput && (
                            <div className="add-address">
                                <div className="row">
                                    <div className="col">
                                        <HeadingStyle title="Add Address" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="row">
                                        <TextField
                                            className="w-100 inputFieldSp"
                                            size="small"
                                            onChange={(e) =>
                                                setAddressFormData({
                                                    ...addressFormData,
                                                    house_no: e.target.value,
                                                })
                                            }
                                            id="id_house_no"
                                            label="Flat. House no."
                                        />
                                        <TextField
                                            className="w-100 inputFieldSp"
                                            size="small"
                                            onChange={(e) =>
                                                setAddressFormData({
                                                    ...addressFormData,
                                                    area: e.target.value,
                                                })
                                            }
                                            id="id_area"
                                            label="Area"
                                        />
                                        <TextField
                                            className="w-100 inputFieldSp"
                                            size="small"
                                            onChange={(e) =>
                                                setAddressFormData({
                                                    ...addressFormData,
                                                    landmark: e.target.value,
                                                })
                                            }
                                            id="id_landmark"
                                            label="Landmark"
                                        />

                                        <Autocomplete
                                            className="inputFieldSp"
                                            size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            onChange={(e: any, v) => getAutoCompleteArrey(v)}
                                            ListboxProps={MenuPropsAutoCompleteNoCheckBox.PaperProps}
                                            options={
                                                location
                                                    ? location?.map(
                                                        (res: {
                                                            id: number;
                                                            city: string;
                                                            state: string;
                                                            country: string;
                                                        }) => {
                                                            return `${res.city}, ${res.state}, ${res.country}`;
                                                        }
                                                    )
                                                    : ["No Data"]
                                            }
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        name="location_id"
                                                        {...params}
                                                        label="Location"
                                                    />
                                                );
                                            }}
                                        />

                                        <TextField
                                            className="w-100 inputFieldSp"
                                            size="small"
                                            onChange={(e) =>
                                                setAddressFormData({
                                                    ...addressFormData,
                                                    pincode: e.target.value,
                                                })
                                            }
                                            id="id_pincode"
                                            label="Pincode"
                                        />
                                        <FormControl
                                            fullWidth
                                            size="small"
                                            className="inputFieldSp"
                                        >
                                            <InputLabel id="demo-multiple-name-label ">
                                                Address type
                                            </InputLabel>
                                            <Select
                                                name="address_type"
                                                size="small"
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                value={addressFormData.residency_type}
                                                onChange={(e) =>
                                                    setAddressFormData({
                                                        ...addressFormData,
                                                        residency_type: e.target.value,
                                                    })
                                                }
                                                label="Address type"
                                                MenuProps={MenuPropsSelect}
                                            >
                                                <MenuItem value={"Home"}>
                                                    Home (7am to 9pm delivery)
                                                </MenuItem>
                                                <MenuItem value={"Office"}>
                                                    Office/Commercial (10am to 6pm delivery)
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControlLabel
                                            defaultChecked={false}
                                            onChange={(e, v) =>
                                                setAddressFormData({
                                                    ...addressFormData,
                                                    default_address: `${v}`,
                                                })
                                            }
                                            control={<Checkbox />}
                                            label="Set as Default"
                                        />
                                        <div className="col save-add-can-buttons">
                                            <LoadingButton
                                                id="id_login_btn"
                                                loading={loading}
                                                loadingPosition="start"
                                                className="btn btn-black"
                                                variant="contained"
                                                onClick={saveAddress}
                                            >
                                                Save
                                            </LoadingButton>
                                            <Button
                                                className="btn"
                                                onClick={() => setShowInput(!showInput)}
                                                variant="outlined"
                                                color="error"
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!showInput && proposalAddressId === 0 ? (
                            <div className="add-address">
                                <ShippingAddressCard
                                    addressList={addressList}
                                    setDefaultAddress={sendAddress}
                                    enableEdit={false}
                                    onChange={updateDefaultAddress}
                                />
                            </div>
                        ) : (
                            <div className='address-list'>
                                {addressList &&
                                    addressList.map((data: any, index: number) => {
                                        if (data.address_id === proposalAddressId) {
                                            return (
                                                <React.Fragment key={index}>
                                                    {data.house_no &&<span className='fs-14 d-block'>{data.house_no}</span>}
                                                    {data.landmark && <span className='fs-14 d-block'>{data.landmark}</span>}
                                                    {data.city && 
                                                        <span className="fs-14 d-block">
                                                            {data.city} {data.pincode && ` - ${data.pincode}`}
                                                        </span>
                                                        }
                                                    {data.state && <span className='fs-14 d-block'>{data.state}</span>}
                                                    {data.country && <span className='fs-14 d-block'>{data.country}</span>}
                                                    
                                                </React.Fragment>
                                            );
                                        }
                                        return null;
                                    })}
                            </div>
                        )}
                    </div>
                </div>

                {
                    <div
                        className={`product-detail-container ${proposalAddressId > 0
                            ? " activefield"
                            : " deactivefield"
                            }`}
                    >
                        <div className="product-detail-title-sec">
                            <h5>
                                <strong>Step 2 (Brand) : </strong> Product Shipping
                                Details{" "}
                            </h5>
                            <h6>
                                The brand shares the product shipping details for your
                                reference.
                            </h6>
                        </div>

                        <div>
                            <div className="shipping_receipt_section">
                                {proposalData && (
                                    <>
                                    {proposalData[0].shipping_details &&
                                      <strong className='d-block fs-14'>Tracking Details:</strong>
                                    }
                                        <span className='d-block mb-1 fs-14'> {proposalData[0].shipping_details}</span>
                                        
                                        {proposalData[0]?.shipping_details &&
                                        <><img
                                            className="shipping_receipt_url mb-3"
                                            src={proposalData[0].shipping_receipt_url}
                                            alt="document"
                                            
                                        />
                                        <button className='btn btn-outline-primary align-items-start mb-2'
                                            style={{cursor:'pointer'}}
                                            // download={proposalData[0].file_name}
                                                onClick={handleCreativeZoom}
                                        >
                                            Download
                                        </button>
                                        </>
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                }
                {
                    <div
                        className={`product-detail-container ${proposalData[0].shipping_receipt_url.length === 0
                            ? " deactivefield"
                            : " activefield"
                            }`}
                    >
                        <div className="product-detail-title-sec">
                            <h5>
                                <strong>Step 3 (Influencer) :</strong> Product Delivery
                                Acknowledgement{" "}
                            </h5>
                            <h6>
                                Acknowledgement by the influencer and creative timeline
                                for your reference.
                            </h6>
                        </div>
                        <div className="w-100">
                            {proposalData &&
                                proposalData[0].product_delivery_status === "Pending" ? (
                                <div className="shipping_receipt_section">
                                    <span>
                                        <Checkbox
                                            className="p-0"
                                            onChange={(e, v) => setTermsAccept(v)}
                                            defaultChecked={false}
                                        />{" "}
                                        Yes, I have received the product.
                                    </span>
                                    <span className="mt-3">
                                        <FormControl style={{minWidth:'250px'}} size="small" >
                                            <InputLabel id="demo-simple-select-label">
                                                Product Condition:
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                // value={age}
                                                defaultValue={""}
                                                label="Product Delivery Status"
                                                onChange={(e) =>
                                                    setProductDelvieryStatus(e.target.value)
                                                }
                                                MenuProps={MenuPropsSelect}
                                            >
                                                <MenuItem value={`Received`}>Good</MenuItem>
                                                <MenuItem value={`Broken`}>Broken</MenuItem>
                                                {/* <MenuItem value={`Rejected`}>Rejected</MenuItem> */}
                                                <MenuItem value={`Expired`}>Expired</MenuItem>
                                                {/* <MenuItem value={`Other`}>Other</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                        <span className='d-flex align-items-center '><span className='fs-14'>Creative will be shared by </span><LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={["shipping_receipt_section"]}
                                            >
                                            <DatePicker
                                                className="ms-2"
                                                label=""
                                                format="DD-MM-YYYY"
                                                // minDate={"12-12-2020"}
                                                minDate={dayjs(new Date())}
                                                // defaultValue={"12-12-2020"}
                                                    onChange={(e: any) =>
                                                        setRecivedProductDate(e?.format(dateFormat))
                                                    }
                                                    slotProps={{
                                                        textField: {
                                                            size: "small",
                                                            // helperText: "MM/DD/YYYY",
                                                        },
                                                    }}
                                                />       
                                            </DemoContainer>
                                        </LocalizationProvider> </span>
                                                                       
                                        <TextField
                                            id="outlined-basic"
                                            label=""
                                            size="small"
                                            variant="outlined"
                                            rows={4}
                                            onChange={(e) =>
                                                setProductDeliveryRemark(e.target.value)
                                            }
                                            className="mt-2 w-100"
                                            placeholder="Comments"
                                        />
                                        <span className="product-image-section">
                                        {(isVisible && productDeliveryImage) &&
                                                <span className='d-inline-flex position-relative mt-3'>
                                                <img
                                                    className="image-style "
                                                    src={productDeliveryImage}
                                                    alt="product"
                                                />
                                                <button
                                                    onClick={handleClosePreviewImage}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-8px',
                                                        right: '-5px',
                                                        background: '#000',
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '50%',
                                                        width: '24px',
                                                        height: '24px',
                                                        cursor: 'pointer',
                                                    }}
                                                    >
                                                    <MaterialSymbol fontSize='14px' className='d-flex align-items-center' iconName='close' fontweight='200' />
                                                    </button>
                                                    </span>
                                        }    
                                        {(!isVisible || !productDeliveryImage) && (
                                            <LoadingButton
                                                loading={loading}
                                                loadingPosition="start"
                                                className="btn btn-outline-primary mt-3"
                                                variant="contained"
                                                onClick={() =>
                                                    setShowUploadPopup(showUploadPopup)
                                                }
                                            >
                                                Upload Product Image
                                            </LoadingButton>
                                        )}
                                        </span>
                                        {showUploadPopup && (
                                            <FileUploadPopup
                                                openModal={showUploadPopup}
                                                onClose={() => setShowUploadPopup(showUploadPopup)}
                                                onSend={(e: { url: string; key: string }) => {
                                                    productDeliveryImageUrl && productDeliveryImageUrl(e.url);
                                                    productDeliveryImagePath && productDeliveryImagePath(e.key);
                                                    setIsVisible(true)
                                                }}
                                                path={"path"}
                                                acceptedFileTypes=".jpg, .jpeg, .png"
                                            />
                                        )}
                                    </span>
                                    <span className="mt-3 mb-3">
                                        <LoadingButton
                                            style={{
                                                marginRight: `15px`,
                                            }}
                                            className="btn btn-primary"
                                            loading={loading}
                                            loadingPosition="start"
                                            variant="contained"
                                            onClick={sendReciviedProduct}
                                        >
                                            Send
                                        </LoadingButton>
                                    </span>
                                </div>
                            ) : (
                                <div className="shipping_receipt_section">
                                    <ul className='ps-0'>
                                        <li>
                                            {" "}
                                            <strong>Product Condition:</strong>{" "}
                                            {
                                                proposalData?.[0]?.product_delivery_status === "Received" 
                                                    ? "Good" 
                                                    : proposalData?.[0]?.product_delivery_status || "No Status"
                                                }
                                        </li>
                                        {/* <li>
                                            {" "}
                                            <strong>Delivery Date: </strong>
                                            {dayjs(
                                                proposalData[0].product_delivery_date
                                            ).format("LL")}
                                        </li> */}
                                        {proposalData[0].product_delivery_remarks &&
                                        <li>
                                            <strong>Comments: </strong>
                                            {proposalData[0].product_delivery_remarks}
                                        </li>
                                        }          
                                        <li>
                                            <span>
                                                <strong>Product Image</strong>
                                            </span>
                                            <img
                                                className="image-style"
                                                src={proposalData[0].product_delivery_image_url}
                                                alt="product delivery"
                                            />
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>


           
            {createPortal(<CommonPopUp
                  key={proposalData[0].proposal_id}
                  className="creative-img-popup barter-shipping-detail"
                  modalContent=""
                openModal={handleCreative}
                  modalId={`creative-img-${proposalData[0].proposal_id}`}
                onClose={()=>handleCloseCreativeImageModal()}
                >
                  <div className="container-fluid">
                    <div className='row creative-detail inner'>
                      <div className='col-md-12'>

                        <div className="creative-detail-img">
                          <img
                            src={proposalData[0].shipping_receipt_url}
                            className="selected-creative"
                            alt="shipping receipt"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CommonPopUp>
                , document.body)}
              


        </div>
    )
}

export default BarterProductInfo