import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import './send-invoice.scss';
import { Loading } from "../../../../redux/action";
import { connect } from "react-redux";
import { InvoiceService, LocalService } from '../../../../core/services';
import CommonPopup from '../../../../components/popup/common-popup';
import { LoadingButton } from '@mui/lab';
import ProposalPaymentTable from "../../../brand/plans/campaings/campaign-view/payments/proposal_payment_table";
// For upload media component
import FileUploadPopup from "../../../../components/popup/file-upload-popup";
// import ProposalPaymentTableWhatsApp from "../../../brand/plans/campaings/campaign-view/payments/proposal_payment_whatsapp_flow";
interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectedCampaign: any;
}

const invoiceService = new InvoiceService()
const localService = new LocalService()

const InfluencerSendInvoice: React.FC<Props> = (props: Props) => {
  const [showInvoiceGenerateBtn, setShowInvoiceGenerateBtn] = useState(false);
  const [loading, setLoading] = useState(false)
  // to store accepted proposal details
  const [acceptedProposalPaymentDetails, setAcceptedProposalPaymentDetails] = useState<any[]>([]);
  
  // for upload Invoice
  // const [file, setFile] = useState<any>()
  const path = `accounts/${props.selectedCampaign.influencerId}/campaign_invoices`;

  const [formData, setFormData] = useState({
    user_id: props?.UserReducer != null  ? props?.UserReducer?.user_id : 0,
    account_id: props.UserReducer != null ? props.UserReducer.account_id : 0,
    influencer_id: props.selectedCampaign.influencerId,
    sm_id: props.selectedCampaign.smId,
    campaign_id: props.selectedCampaign.campaign_id,
    proposal_id: props.selectedCampaign.proposalId,
    file_path: '',
    file_url: '',
    file_type: '',
    file_name: '',
    storage_size: '',
  });

  // set foc popup and gstin popup state
  const [popUps, setPopUps] = useState({
    openUploadInvoiceModal: false,
    openUpdateSettingsModal: false
  });
  const getParams = new URLSearchParams(window.location.search);

  async function showGenrateInvoiceButton() {
    try {
      let influencer_id = 0;
      if(props.UserReducer !== null){
        let sm_id = Number(getParams.get("smId"));
        if (props.selectedCampaign.campaign_type !== 'Barter') {
  
          for (let data of props.UserReducer.influencer_ids) {
            if ((props.selectedCampaign.campaign_platform === data.platform_type) && (sm_id === (data.ig_id || data.yt_id))) {
              influencer_id = data?.influencer_id;
              sm_id = data?.ig_id || data.yt_id;
            }
          }
        }

        if (influencer_id > 0) {
          const response = await invoiceService.allowToGenrateInvoice({
            user_id: props?.UserReducer != null  ? props?.UserReducer?.user_id : 0,
            account_id: props.UserReducer  != null ? props.UserReducer.account_id : 0,
            campaign_id: props.selectedCampaign.campaign_id || 0,
            influencer_id: influencer_id ,
            sm_id: sm_id
          });
          setShowInvoiceGenerateBtn(response.data.status.status);
        }
  
      }
      else{
        const response = await invoiceService.allowToGenrateInvoice({
          user_id: 0,
          account_id: 0,
          campaign_id: props.selectedCampaign.campaign_id || 0,
          influencer_id: props.selectedCampaign.influencerId ,
          sm_id: props.selectedCampaign.smId
        });
        setShowInvoiceGenerateBtn(response.data.status.status);

      }
    } catch (error) {
      throw new Error(`showGenrateInvoiceButton error: ${error}`);
    }
  }

  async function getAcceptedProposalPaymentDetails() {
    try {
      const response = await invoiceService.getAcceptedProposalPaymentDetails({
        campaign_id: props.selectedCampaign.campaign_id,
        influencer_id: props.selectedCampaign.influencerId
      });

      if (response.data.status.status) {
        setAcceptedProposalPaymentDetails(response.data.rows);
      } else {
        setAcceptedProposalPaymentDetails([]);
      }
    } catch (error) {
      throw new Error(`getAcceptedProposalPaymentDetails error :: ${error}`);
    }
  }

  useEffect(() => {
    //temp comment
    showGenrateInvoiceButton();
    getAcceptedProposalPaymentDetails();
  }, [props.selectedCampaign, props.UserReducer]);
  async function generate_invoice() {
    try {
      setLoading(true);
      let influencer_id = 0;
      let sm_id = Number(getParams.get("smId"));
      for (let data of props.UserReducer.influencer_ids) {
        if ((props.selectedCampaign.campaign_platform === data.platform_type) && (sm_id === (data.ig_id || data.yt_id))) {
          influencer_id = data?.influencer_id;
          sm_id = data?.ig_id || data.yt_id;
        }
      }

      if (influencer_id > 0) {
        const response = await invoiceService.generateInvoice({
          user_id: props.UserReducer.user_id,
          account_id: props.UserReducer.account_id,
          campaign_id: props.selectedCampaign.campaign_id,
          proposal_id: props.selectedCampaign.proposalId,
          campaign_platform: props.selectedCampaign.campaign_platform,
          influencer_id: influencer_id,
          sm_id: sm_id
        });

        if (response.data.status.status) {
          // let baseUrl = window.location.origin + '/print/invoice';
          let baseUrl = `${window.location.origin}/print/invoice?id=${response.data.rows[0].id}`;
          window.open(baseUrl, "_blank");
        } else {
          // localService.toastify(response.data.status.message, 'info', 1000);
          setPopUps({...popUps, openUpdateSettingsModal: true})
        }
      }
    } catch (error) {
      throw new Error(`generate_invoice :: ${error}`);
    } finally {
      setLoading(false);
    }
  }
  function handleCloseUploadInvoiceModal() {
    setPopUps({...popUps, openUploadInvoiceModal: false});
  }

  function handleCloseUpdateSettingsModal() {
    setPopUps({...popUps, openUpdateSettingsModal: false});
  }

  const insertCampaignInvoiceDetail = async (e:any) => {
    try{
      const updatedFormData = {
        ...formData,
        file_url: e.url,
        file_path: e.key,
        file_type: e.fileType,
        file_name: e.fileName,
        storage_size: e.storage_size
      };
      setFormData(updatedFormData);
      
      const response = await invoiceService.uploadCampaignInvoice(updatedFormData);
      const checkStatus = response.data.status.status ? 'success' : 'error';
      // localService.toastify(response.data.status.message, checkStatus, 1000);
      handleCloseUploadInvoiceModal();
    } catch (error) {
      console.error(`Something went wrong :: ${error}`);
    } finally {
      setLoading(false);
      getAcceptedProposalPaymentDetails();
    }
  };
  return (
    <div className="invoice-info-sec">

      <div className="row generate_invoice mt-3 mb-3  align-items-center justify-content-center">

          <div className="invoice-upload-generate">
          <div>
            <button disabled={!showInvoiceGenerateBtn} type='button' className='btn btn-outline-primary' onClick={() => setPopUps({...popUps, openUploadInvoiceModal: true})}>Upload Invoice</button>
            {    
            props.UserReducer !==null &&  
            <button
              disabled={!showInvoiceGenerateBtn}
              className='btn btn-outline-primary ms-2 me-2'
              onClick={generate_invoice}
            >
              Generate Invoice
            </button>
            }
            </div>
            {!showInvoiceGenerateBtn && <span>you can't upload/generate the invoice until your proposal is accepted.</span>}
          </div>
        {popUps.openUploadInvoiceModal && (
          <FileUploadPopup 
            openModal={popUps.openUploadInvoiceModal} 
            onClose={(e) => handleCloseUploadInvoiceModal()} 
            onSend={(e: any) =>
              insertCampaignInvoiceDetail(e)
            }
            path={path}
            acceptedFileTypes=".doc, .docx, .pdf" 
          />
        )}
        <CommonPopup
          modalTitle="Update details"
          modalContent="One or more items from the below mentioned details is missing. To generate the invoice, please complete the following details. This is a one time process and wont take more than a few minutes."
          openModal={popUps.openUpdateSettingsModal}
          onClose={() => handleCloseUpdateSettingsModal()}
          className="modal-medium mx-auto"
        >
          <div>
            <ol>
              <li>
                Address details
              </li>
              <li>
                Company details 
                <small> (Optional)</small>
              </li>
              <li>
                Account details
              </li>
            </ol>
            <NavLink
              className="btn btn-primary mb-2"
              to="/influencer/settings/companydetails"
              target="_blank"
            >
              Update now
            </NavLink>
          </div>
        </CommonPopup>
      </div>
      {showInvoiceGenerateBtn &&
        <ProposalPaymentTable UserReducer={props?.UserReducer != null ? props?.UserReducer : {user_id: 0, account_id: 0, influencer_id: props.selectedCampaign.influencerId}} acceptedProposalPaymentDetails={acceptedProposalPaymentDetails} selectedCampaign={props.selectedCampaign} />
      }
      {/* {showInvoiceGenerateBtn && props?.UserReducer == null &&
        <ProposalPaymentTableWhatsApp acceptedProposalPaymentDetails={acceptedProposalPaymentDetails} selectedCampaign={props.selectedCampaign} />
      } */}

      </div >
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectedCampaign: state.InfSelectCamp.campaign,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerSendInvoice);

export { connectToRedux as InfluencerSendInvoice };